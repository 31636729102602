<template>
    <div>
        <div class="d-flex justify-content-center">
            <b-overlay :show="busySaving">
                <div class="d-flex flex-column align-items-center">
                    <div class="align-items-center">
                        <img src="@/assets/images/darkroots_transparent.webp" alt height="150" />
                    </div>
                    <h4>Membership Application</h4>
                    <span>Fill in your details below and you'll get an email with the next steps to become a
                        member!</span>

                    <hr />
                    <div>
                        <b-form class="mw-500 mb-4">
                            <!-- Your form groups and inputs here -->
                            <b-form-group id="input-group-first-name" label="First Name" label-for="input-first-name">
                                <b-form-input id="input-first-name" name="input-first-name"
                                    aria-describedby="input-first-name-feedback" class="input-500"
                                    v-model="$v.form.first_name.$model"
                                    :state="validateState('first_name')"></b-form-input>
                                <b-form-invalid-feedback id="input-first-name-feedback">
                                    This is a required field and must be at least 2 characters.
                                </b-form-invalid-feedback>
                            </b-form-group>

                            <b-form-group id="input-group-last-name" label="Last Name" label-for="input-last-name">
                                <b-form-input id="input-last-name" name="input-last-name"
                                    v-model="$v.form.last_name.$model"
                                    :state="validateState('last_name')"></b-form-input>
                                <b-form-invalid-feedback id="input-last-name-feedback">
                                    This is a required field and must be at least 2 characters.
                                </b-form-invalid-feedback>
                            </b-form-group>

                            <b-form-group id="input-group-contact-no" label="Contact No" label-for="input-contact-no">
                                <b-form-input id="input-contact-no" name="input-contact-no"
                                    v-model="$v.form.contact_no.$model"
                                    :state="validateState('contact_no')"></b-form-input>
                                <b-form-invalid-feedback id="input-contact-no-feedback">
                                    Contact Numbers must be 10 digits.
                                </b-form-invalid-feedback>
                            </b-form-group>

                            <b-form-group id="input-group-id-no" label="ID No" label-for="input-id-no">
                                <b-form-input id="input-id-no" name="input-id-no" v-model="$v.form.id_no.$model"
                                    :state="validateState('id_no')"></b-form-input>
                                <b-form-invalid-feedback id="input-id-no-feedback">
                                    ID Numbers must be 13 digits.
                                </b-form-invalid-feedback>
                            </b-form-group>

                            <!-- Date of Birth Input -->
                            <b-form-group label="Date of Birth (DD-MM-YYYY):" label-for="dob-input">
                                <b-form-input id="dob-input" v-model="$v.form.dob.$model" :state="validateState('dob')"
                                    placeholder="DD-MM-YYYY" @input="formatDOB($event)"></b-form-input>
                                <b-form-invalid-feedback id="dob-input-feedback">
                                    Date should be in DD-MM-YYYY format, and you must be at least 21 years old.
                                </b-form-invalid-feedback>
                            </b-form-group>


                            <b-form-group label="Address">
                                <b-form-input id="address-input" v-model="$v.form.street.$model" :state="validateState('street')"
                                    placeholder="123 Main St"></b-form-input>
                            </b-form-group>

                            <b-form-group label="City">
                                <b-form-input id="city-input" v-model="$v.form.city.$model" :state="validateState('city')"
                                    placeholder="City"></b-form-input>
                            </b-form-group>

                            <b-form-group label="Postal Code">
                                <b-form-input id="postal-input" v-model="$v.form.postal_code.$model" :state="validateState('postal_code')"
                                    placeholder="Postal Code"></b-form-input>
                            </b-form-group>

                            <b-form-group label="Province">
                                <b-form-select v-model="$v.form.province.$model" :state="validateState('province')"
                                    :options="province_options" id="province" name="province"
                                    aria-describedby="province-feedback"></b-form-select>
                                <b-form-invalid-feedback id="province-feedback">
                                    This is a required field.
                                </b-form-invalid-feedback>
                            </b-form-group>

                            <b-form-group id="input-group-email" label="Email" label-for="input-email">
                                <b-form-input name="input-email" v-model="$v.form.email.$model"
                                    :state="validateState('email')" type="email"></b-form-input>
                                <b-form-invalid-feedback id="input-email-feedback">
                                    Needs to be a valid email format.
                                </b-form-invalid-feedback>
                            </b-form-group>

                            <b-form-group label="Branch">
                                <b-form-select v-model="$v.form.branch_id.$model" :state="validateState('branch_id')"
                                    :options="branchesForSelect" id="branch" name="branch"
                                    aria-describedby="branch-feedback"></b-form-select>
                                <b-form-invalid-feedback id="branch-feedback">
                                    This is a required field.
                                </b-form-invalid-feedback>
                            </b-form-group>

                            <b-form-checkbox id="checkbox-newsletter" v-model="form.newsletter"
                                name="checkbox-newsletter" value="true" unchecked-value="false" size="lg"
                                class="float-left">
                                Newsletter
                            </b-form-checkbox>
                        </b-form>
                        <div class="modal-footer mt-4">
                            <b-button @click="saveLead" variant="primary">Submit Application</b-button>
                            <b-button @click="clear" variant="warning">Clear / Cancel</b-button>
                        </div>
                    </div>
                </div>
            </b-overlay>
        </div>
    </div>
</template>

<script>
import { crmMethods, crmComputed, adminMethods, adminComputed } from "@/state/helpers";
import { required, minLength, maxLength, email } from "vuelidate/lib/validators";
import Swal from "sweetalert2";

export default {
    data: () => ({
        busySaving: false,
        formSubmit: false,
        form: {
            first_name: "",
            last_name: "",
            contact_no: "",
            id_no: "",
            email: "",
            province: null,
            dob: "",
            street: "",
            city: "",
            postal_code: "",
            type: null,
            newsletter: "false",
            branch_id: null
        },
        province_options: [
            { value: null, text: "Please select an option" },
            { value: "Western Cape", text: "Western Cape" },
            { value: "Eastern Cape", text: "Eastern Cape" },
            { value: "Free State", text: "Free State" },
            { value: "Gauteng", text: "Gauteng" },
            { value: "KwaZulu-Natal", text: "KwaZulu-Natal" },
            { value: "Limpopo", text: "Limpopo" },
            { value: "Mpumalanga", text: "Mpumalanga" },
            { value: "North-West", text: "North-West" },
            { value: "Northern-Cape", text: "Northern-Cape" }
        ]
    }),
    validations: {
        form: {
            first_name: {
                required,
                minLength: minLength(2)
            },
            last_name: {
                required,
                minLength: minLength(2)
            },
            contact_no: {
                required,
                minLength: minLength(10),
                maxLength: maxLength(10)
            },
            id_no: {
                required,
                minLength: minLength(13),
                maxLength: maxLength(13)
            },
            dob: {
                required,
                customDateValidator(value) {
                    if (!value) return false;
                    const regex = /^\d{2}-\d{2}-\d{4}$/;
                    if (!regex.test(value)) return false;

                    const [day, month, year] = value.split('-');
                    const birthDate = new Date(`${year}-${month}-${day}`);
                    return (
                        birthDate &&
                        birthDate.getFullYear() === parseInt(year) &&
                        this.isTwentyOneOrOlder(birthDate.toISOString())
                    );
                }
            },
            email: {
                required,
                email
            },
            branch_id: {
                required
            },
            province: {
                required
            },
            street: {
                required
            },
            city: {
                required
            },
            postal_code: {
                required
            }
        }
    },
    computed: {
        ...crmComputed,
        ...adminComputed,
        branchesForSelect() {
            return [
                {
                    value: null,
                    text: "Please select an option",
                    disabled: true
                },
                ...this.branches.map(branch => ({
                    value: branch.id,
                    text: branch.name.trim()
                }))
            ];
        }
    },
    methods: {
        ...adminMethods,
        ...crmMethods,
        clear() {
            Swal.fire({
                title: `Clear the form?`,
                text: `Are you sure?`,
                icon: "warning",
                showCancelButton: true,
                confirmButtonColor: "#34c38f",
                cancelButtonColor: "#f46a6a",
                confirmButtonText: "Confirm"
            }).then(result => {
                if (result.value) {
                    this.reset();
                }
            });
        },
        reset() {
            this.$set(this, 'form', {
                first_name: "",
                last_name: "",
                contact_no: "",
                id_no: "",
                email: "",
                province: null,
                dob: "",
                street: "",
                city: "",
                postal_code: "",
                type: null,
                newsletter: "false",
                branch_id: null
            });
            this.formSubmit = false;
            this.busySaving = false;
        },
        validateState(name) {
            if (this.formSubmit) {
                const { $dirty, $error } = this.$v.form[name];
                return $dirty ? !$error : null;
            }
        },
        saveLead() {
            this.formSubmit = true;
            this.$v.form.$touch();
            if (this.$v.form.$anyError) {
                return;
            }
            this.busySaving = true;
            this.form.newsletter = this.form.newsletter === "true";
            this.saveIncomingSalesLead(this.form).then(() => {
                this.busySaving = false;
                Swal.fire({
                    title: 'Application Successful',
                    text: 'Please check your email for further instructions.',
                    icon: 'success',
                    confirmButtonText: 'OK'
                }).then(() => {
                    window.location.href = 'https://www.darkroots.club/';
                });
            }).catch((error) => {
                Swal.fire(error.data.message, "", "error");
                this.busySaving = false;
            });
        },
        formatDOB(value) {
            let input = value.replace(/\D/g, '');
            if (input.length >= 2) input = input.slice(0, 2) + '-' + input.slice(2);
            if (input.length >= 5) input = input.slice(0, 5) + '-' + input.slice(5);
            this.form.dob = input;
        },
        isTwentyOneOrOlder(dateString) {
            const birthDate = new Date(dateString);
            const today = new Date();
            let age = today.getFullYear() - birthDate.getFullYear();
            const m = today.getMonth() - birthDate.getMonth();
            if (m < 0 || (m === 0 && today.getDate() < birthDate.getDate())) {
                age--;
            }
            return age >= 21;
        }
    },
    mounted() {
        this.getBranches();
    }
};
</script>

<style lang="scss" scoped>
.mw-500 {
    max-width: 500px !important;
}

.input-500 {
    width: 500px;
}

@media only screen and (max-width: 510px) {
    .input-500 {
        width: 300px;
    }
}

@media only screen and (max-width: 310px) {
    .input-500 {
        width: 200px;
    }
}
</style>