<template>
    <div>
        <!-- {{ orderData }} -->
        <div v-if="all_products_loading_status == true" class="text-center">
            <b-spinner small type="grow"></b-spinner>
            Loading products...
        </div>

        <div v-else class="checkout-tabs parent-of-ul">
            <!-- {{ orderData }} -->
            <b-tabs pills horizontal nav-class="p-0" nav-wrapper-class="col-lg-12 w-100" id="target_ul_class" v-model="tabIndex">
                <b-tab active tabindex="0">
                    <template v-slot:title class="b-tab-class">
                        <i class="fas fa-check-circle d-block check-nav-icon mt-4 mb-2"></i>
                        <p class="font-weight-bold mb-4">Product Selection</p>
                    </template>
                    <b-card-text>
                        <!-- <b-button variant="primary"> <i class="mdi mdi-table check-nav-icon icon-resize"></i></b-button>
                        <i class="mdi mdi-table check-nav-icon icon-resize"></i> -->

                        <div class="card">
                            <div class="card-body">
                                <div class="card shadow-none border mb-0">
                                    <div class="row mt-2 mr-4">
                                        <div class="col-sm-6">
                                            <router-link tag="a" to="/ecommerce/cart" class="btn text-muted d-none d-sm-inline-block btn-link">
                                                <!-- <i class="mdi mdi-arrow-left mr-1"></i> Back to Shopping Cart -->
                                            </router-link>
                                        </div>
                                        <!-- end col -->

                                       <div class="col-sm-6">
                                            <div class="text-sm-right">
                                                <button @click="validateAdditionalInformation()" class="btn btn-success">
                                                    <i class="mdi mdi-truck-fast mr-1"></i>
                                                    Proceed to Cart
                                                </button>
                                                <div v-if="gridView == false" class="mt-2">
                                                    <button @click="toggleView()" class="btn btn-info">
                                                        <i class="mdi mdi-view-grid-outline mr-1"></i>
                                                        Grid View
                                                    </button>
                                                </div>
                                                <div v-else class="mt-2">
                                                    <button @click="toggleView()" class="btn btn-info">
                                                        <i class="mdi mdi-table mr-1"></i>
                                                        Table View
                                                    </button>
                                                </div>
                                            </div>
                                        </div> 

                                        <!-- end col -->
                                    </div>
                                    <div class="row">
                                        <div class="col-lg-2">
                                            <h4 class="card-title mt-4 mb-2 ml-4 font-size-20">Filter</h4>
                                            <div class="mt-1 ml-5 pt-3 mb-5">
                                                <h5 class="font-size-18 mb-3">Categories</h5>

                                                <b-form-checkbox
                                                    v-for="productGroup in renderCategoriesProductSelection"
                                                    :key="productGroup.id"
                                                    size="lg"
                                                    class="mt-2 ml-2"
                                                    value="active"
                                                    unchecked-value="not_active"
                                                    @change="categoryFilter($event, productGroup.name)"
                                                    >{{ productGroup.name }}</b-form-checkbox
                                                >
                                            </div>
                                        </div>
                                        <div v-if="gridView" class="col-lg-10">
                                            <b-row class="d-flex">
                                                <b-form-input
                                                    size="lg"
                                                    @input="searchFilter($event, all_products)"
                                                    placeholder="Search for products..."
                                                    class="mt-3 ml-5 mr-5 w-100"
                                                >
                                                </b-form-input>
                                            </b-row>
                                            <b-row class="d-flex">
                                                <div class="">
                                                    <div class="card-body">
                                                        <!-- <div class="card shadow-none border mb-0"> -->
                                                        <div class="card-body">
                                                            <div class="row mb-3 d-flex">
                                                                <!-- <div v-for="data in clothsData" :key="data.id" class="col-xl-4 col-sm-6"> -->
                                                                <div
                                                                    v-for="product in paginatedItemsProductSelectionTab"
                                                                    :key="product.id"
                                                                    class="card ml-3"
                                                                >
                                                                    <div class="card-body">
                                                                        <div class="product-img d-flex justify-content-center position-relative">
                                                                            <!-- <div class="avatar-sm product-ribbon mt-1 mr-1">
                                                                                <span class="avatar-title rounded-circle bg-primary">Cart</span>
                                                                            </div> -->
                                                                            <!-- {{ product.public_img_src }} -->
                                                                            <img class="img-class text-center" :src="product.public_img_src" />
                                                                        </div>
                                                                        <div class="mt-4 text-center">
                                                                            <h5 class="mb-3 product-name-max-length text-left font-weight-bold">
                                                                                {{ product.name }}
                                                                            </h5>
                                                                            <!-- {{ product }}
                                                                            {{ handleProductGroups() }} -->
                                                                            <!-- <h5 v-for="pivot in product.pivot" :key="pivot.id" class="text-left">
                                                                                Group: {{ pivot.group_id }}
                                                                            </h5> -->
                                                                            <!-- <h5 class="text-left">Groups: {{ getGroupNames(product.groups) }}</h5> -->

                                                                            <!-- {{ group }} -->
                                                                            <p class="text-left product-description"><strong>Description:</strong> {{ product.description }}</p>
                                                                            <h5 class="text-left">SKU: {{ product.sku }}</h5>
                                                                            <h5 class="text-left font-weight-bold">R {{ product.price }}</h5>

                                                                            <!-- {{ !lookIfInCart(product.id) }} -->
                                                                            <b-button
                                                                                v-if="!lookIfInCart(product.id)"
                                                                                @click="productSelectionClick(product.id)"
                                                                                variant="primary"
                                                                                class="mt-2 pl-4 pr-4"
                                                                                size="lg"
                                                                            >
                                                                                <i class="fas fa-shopping-basket check-nav-icon mr-2"></i> Add to
                                                                                Cart</b-button
                                                                            >
                                                                            <!-- the buttons section -->
                                                                            <div v-else mt-3 class="d-flex justify-content-center ml-3">
                                                                                <!-- {{ orderData }} -->
                                                                                <div
                                                                                    v-for="order in orderData.products"
                                                                                    v-bind:key="order.id"
                                                                                    class="d-flex justify-content-center"
                                                                                >
                                                                                    <div
                                                                                        v-if="product.id == order.id"
                                                                                        class="d-flex justify-content-center"
                                                                                    >
                                                                                        <input
                                                                                            @change="onChangeCartInput(product.id)"
                                                                                            v-model="order.qty"
                                                                                            type="number"
                                                                                            name="qty"
                                                                                            style="width: 60px"
                                                                                            min="0"
                                                                                            max="999"
                                                                                            class="text-center"
                                                                                        />&nbsp;

                                                                                        <b-button
                                                                                            variant="primary"
                                                                                            @click="editCartQuanityIncrease(product.id)"
                                                                                            class="btn btn-primary bootstrap-touchspin-up"
                                                                                            >+</b-button
                                                                                        >
                                                                                        <b-button
                                                                                            variant="primary"
                                                                                            @click="editCartQuanityDecrease(product.id)"
                                                                                            class="btn bootstrap-touchspin-down ml-1"
                                                                                            >-</b-button
                                                                                        >
                                                                                        <!-- <button
                                                                                            class="btn btn-primary bootstrap-touchspin-up"
                                                                                            type="button"
                                                                                            @click="editCartQuanityIncrease(product.id)"
                                                                           
                                                                                            +</button
                                                                                        >&nbsp;
                                                                                        <button
                                                                                            class="btn btn-primary bootstrap-touchspin-down"
                                                                                            type="button"
                                                                                            @click="editCartQuanityDecrease(product.id)"
                                                                                        >
                                                                                            -
                                                                                        </button> -->
                                                                                    </div>
                                                                                </div>
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <!-- </div> -->
                                                </div></b-row
                                            >

                                            <div class="pagination-container">
                                                <b-pagination
                                                    v-show="products.length > 5"
                                                    v-model="currentPageProductSelectionTab"
                                                    :per-page="perPageProductSelectionTab"
                                                    :total-rows="productSelectionRows"
                                                    size="lg"
                                                    @input="onPaginationChangeProductSelectionTab"
                                                    first-text="First"
                                                    prev-text="Prev"
                                                    next-text="Next"
                                                    last-text="Last"
                                                    align="center"
                                                >
                                                </b-pagination>
                                            </div>
                                        </div>
                                        <div v-else class="col-lg-10">
                                            <div class="card">
                                                <div class="card-body">
                                                    <div class="table-responsive">
                                                        <table
                                                            class="table table-centered mb-0 table-nowrap table-striped table-bordered"
                                                            :items="orderData.products"
                                                            :current="currentPage"
                                                        >
                                                            <thead class="darker-table-head">
                                                                <tr>
                                                                    <th></th>
                                                                    <th>Product</th>
                                                                    <th>Description</th>
                                                                    <th>Price</th>
                                                                    <th>Action</th>
                                                                </tr>
                                                            </thead>
                                                            <tbody>
                                                                <!-- {{
                                                                    paginatedItemsProductSelectionTab
                                                                }} -->
                                                                <!-- {{
                                                                    this.products[0]
                                                                }} -->
                                                                <!-- {{
                                                                    orderData.products
                                                                }} -->
                                                                <tr v-for="product in products" :key="product.id">
                                                                    <td class="text-center">
                                                                        <img
                                                                            v-if="product.public_img_src"
                                                                            :src="product.public_img_src"
                                                                            alt="product-img"
                                                                            title="product-img"
                                                                            class="avatar-sm"
                                                                        />
                                                                        <img
                                                                            v-else
                                                                            src="#"
                                                                            alt="product-img"
                                                                            title="product-img"
                                                                            class="avatar-sm"
                                                                        />
                                                                    </td>
                                                                    <td>{{ product.name }}</td>
                                                                    <td class="td-text-wrap">{{ product.description }}</td>
                                                                    <td>R {{ product.price }}</td>
                                                                    <td>
                                                                        <div class="text-center">
                                                                            <!-- {{ !lookIfInCart(product.id) }} -->
                                                                            <b-button
                                                                                v-if="!lookIfInCart(product.id)"
                                                                                @click="productSelectionClick(product.id)"
                                                                                variant="primary"
                                                                                class="mt-2 pl-4 pr-4"
                                                                                size="md"
                                                                            >
                                                                                <i class="fas fa-shopping-basket check-nav-icon mr-2"></i> Add to
                                                                                Cart</b-button
                                                                            >
                                                                            <!-- the buttons section -->
                                                                            <div v-else mt-3 class="d-flex justify-content-center ml-3">
                                                                                <!-- {{ orderData }} -->
                                                                                <div
                                                                                    v-for="order in orderData.products"
                                                                                    v-bind:key="order.id"
                                                                                    class="d-flex justify-content-center"
                                                                                >
                                                                                    <div
                                                                                        v-if="product.id == order.id"
                                                                                        class="d-flex justify-content-center"
                                                                                    >
                                                                                        <input
                                                                                            @change="onChangeCartInput(product.id)"
                                                                                            v-model="order.qty"
                                                                                            type="number"
                                                                                            name="qty"
                                                                                            style="width: 60px"
                                                                                            min="0"
                                                                                            max="999"
                                                                                            class="text-center"
                                                                                        />&nbsp;

                                                                                        <b-button
                                                                                            variant="primary"
                                                                                            @click="editCartQuanityIncrease(product.id)"
                                                                                            class="btn btn-primary bootstrap-touchspin-up"
                                                                                            >+</b-button
                                                                                        >
                                                                                        <b-button
                                                                                            variant="primary"
                                                                                            @click="editCartQuanityDecrease(product.id)"
                                                                                            class="btn bootstrap-touchspin-down ml-1"
                                                                                            >-</b-button
                                                                                        >
                                                                                        <!-- <button
                                                                                            class="btn btn-primary bootstrap-touchspin-up"
                                                                                            type="button"
                                                                                            @click="editCartQuanityIncrease(product.id)"
                                                                           
                                                                                            +</button
                                                                                        >&nbsp;
                                                                                        <button
                                                                                            class="btn btn-primary bootstrap-touchspin-down"
                                                                                            type="button"
                                                                                            @click="editCartQuanityDecrease(product.id)"
                                                                                        >
                                                                                            -
                                                                                        </button> -->
                                                                                    </div>
                                                                                </div>
                                                                            </div>
                                                                        </div>
                                                                    </td>
                                                                    <!-- <td>
                                                                        <a
                                                                            href="#"
                                                                            @click="removeOrderProduct(product.id)"
                                                                            class="action-icon text-danger"
                                                                        >
                                                                            <i class="mdi mdi-trash-can font-size-18"></i>
                                                                        </a>
                                                                    </td> -->
                                                                </tr>
                                                            </tbody>
                                                        </table>
                                                    </div>

                                                    <div class="row mt-3">
                                                        <!-- end col -->
                                                        <div class="col-sm-6">
                                                            <div class="text-sm-right mt-2 mt-sm-0">
                                                                <!-- <router-link to="/ecommerce/checkout"
                                                                    class="btn btn-success">
                                                                    <i class="mdi mdi-cart-arrow-right mr-1"></i> Checkout
                                                                </router-link> -->
                                                            </div>
                                                        </div>
                                                        <!-- end col -->
                                                    </div>
                                                    <!-- end row-->
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </b-card-text>
                </b-tab>

                <b-tab tabindex="2">
                    <template v-slot:title>
                        <i class="fas fa-shopping-basket d-block check-nav-icon mt-4 mb-2"></i>
                        <p v-if="orderData.products.length == 0" class="font-weight-bold mb-4">Cart</p>
                        <p v-else class="font-weight-bold mb-4">Cart ({{ totalItemsInCart() }})</p>
                        <!-- <p v-else class="font-weight-bold mb-4">Cart</p> -->
                        <!-- <p v-else class="font-weight-bold mb-4">Cart ({{ orderData.products.length }})</p> -->
                    </template>
                    <b-card-text>
                        <div class="card">
                            <div class="card-body">
                                <div class="card shadow-none border mb-0">
                                    <div class="row mt-2 mr-4">
                                        <div class="col-sm-6">
                                            <router-link tag="a" to="/ecommerce/cart" class="btn text-muted d-none d-sm-inline-block btn-link">
                                                <!-- <i class="mdi mdi-arrow-left mr-1"></i> Back to Shopping Cart -->
                                            </router-link>
                                        </div>
                                        <!-- end col -->
                                        <div class="col-sm-6">
                                            <div class="text-sm-right">
                                                <div class="text-sm-right">
                                                    <button @click="validateCart()" class="btn btn-success">
                                                        <i class="mdi mdi-truck-fast mr-1"></i>
                                                        Proceed to Shipping Info
                                                    </button>
                                                </div>
                                            </div>
                                        </div>
                                        <!-- end col -->
                                    </div>
                                    <div class="card-body">
                                        <div>
                                            <div class="row">
                                                <div class="col-xl-12">
                                                    <div class="card mb-0">
                                                        <div class="card-body">
                                                            <!-- <div class="card"> -->
                                                            <!-- <div class="card-body"> -->
                                                            <vue-bootstrap-autocomplete
                                                                v-model="addProduct"
                                                                noResultsInfo="No matching products found"
                                                                placeholder="Search for a product here"
                                                                @hit="addOrderProduct()"
                                                                :data="searchProducts"
                                                                class=""
                                                            >
                                                            </vue-bootstrap-autocomplete>
                                                            <!-- </div> -->
                                                            <!-- ! NB -->
                                                            <!-- {{ searchProducts }} -->
                                                            <!-- </div> -->
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                            <div class="row">
                                                <div :class="showPrices ? 'col-xl-9' : 'col-xl-12'">
                                                    <div class="card">
                                                        <div class="card-body">
                                                            <b-alert v-show="shippingApplies" show variant="primary"><i class="bx bxs-truck"></i> Free delivery applies to orders over R1000</b-alert>
                                                            <div v-if="paginatedItems.length > 0" class="table-responsive">
                                                                <table
                                                                    class="table table-centered mb-0 table-nowrap table-striped table-bordered"
                                                                    :items="orderData.products"
                                                                    :current="currentPage"
                                                                >
                                                                    <thead class="darker-table-head">
                                                                        <tr>
                                                                            <th>Product</th>
                                                                            <th>Product Desc</th>
                                                                            <th v-show="showPrices">Price</th>
                                                                            <th>Quantity</th>
                                                                            <th colspan="2" v-show="showPrices">Total</th>
                                                                        </tr>
                                                                    </thead>
                                                                    <tbody>
                                                                        <tr v-for="(product, key) in paginatedItems" :key="product.id">
                                                                            <td>
                                                                                <img
                                                                                    v-if="product.public_img_src"
                                                                                    :src="product.public_img_src"
                                                                                    alt="product-img"
                                                                                    title="product-img"
                                                                                    class="avatar-sm"
                                                                                />
                                                                                <img
                                                                                    v-else
                                                                                    src="#"
                                                                                    alt="product-img"
                                                                                    title="product-img"
                                                                                    class="avatar-sm"
                                                                                />
                                                                                <!-- <img
                                                                            src="#"
                                                                            alt="product-img"
                                                                            title="product-img"
                                                                            class="avatar-md"
                                                                        /> -->
                                                                            </td>
                                                                            <td>
                                                                                <h5 class="font-size-14 text-truncate">
                                                                                    <span class="text-dark">{{ product.name }}</span>
                                                                                </h5>
                                                                                <p class="mb-0">
                                                                                    SKU :
                                                                                    <span class="font-weight-medium">{{ product.sku }}</span>
                                                                                </p>
                                                                               <!--  <p class="mb-0">
                                                                                    GROUP :
                                                                                    <span class="font-weight-medium">{{ getGroupNames(product.groups) }}</span>
                                                                                </p> -->
                                                                            </td>
                                                                            <td v-show="showPrices">
                                                                                R{{ renderPrice(product.price, product.qty) }}
                                                                            </td>
                                                                            <td>
                                                                                <div class="me-3" style="width: 110px">
                                                                                    <div
                                                                                        class="input-group bootstrap-touchspin bootstrap-touchspin-injected"
                                                                                    >
                                                                                        <input
                                                                                            type="text"
                                                                                            name="qty"
                                                                                            class="qty-width"
                                                                                            v-model="product.qty"
                                                                                        />&nbsp;<span class="input-group-btn-vertical"
                                                                                            ><button
                                                                                                class="btn btn-primary bootstrap-touchspin-up"
                                                                                                type="button"
                                                                                                @click="editProductQty(product.id, product.qty + 1)"
                                                                                            >
                                                                                                +</button
                                                                                            >&nbsp;<button
                                                                                                class="btn btn-primary bootstrap-touchspin-down"
                                                                                                type="button"
                                                                                                @click="editProductQty(product.id, product.qty - 1)"
                                                                                            >
                                                                                                -
                                                                                            </button></span
                                                                                        >
                                                                                    </div>
                                                                                </div>
                                                                            </td>
                                                                            <!-- <td><b-form-input id="qty" v-model="product.qty"></b-form-input></td> -->
                                                                            <td v-show="showPrices">
                                                                                <span class="font-weight-bold font-size-14 mr-1">R</span
                                                                                >{{ renderTotal(key) }}
                                                                            </td>
                                                                            <td>
                                                                                <a
                                                                                    href="#"
                                                                                    @click="removeOrderProduct(product.id)"
                                                                                    class="action-icon text-danger"
                                                                                >
                                                                                    <i class="mdi mdi-trash-can font-size-18"></i>
                                                                                </a>
                                                                            </td>
                                                                        </tr>
                                                                    </tbody>
                                                                </table>
                                                            </div>
                                                            <div v-else class="ml-3">No prodcuts in your cart</div>
                                                            <div class="row mt-3">
                                                                <!-- end col -->
                                                                <div class="col-sm-6">
                                                                    <div class="text-sm-right mt-2 mt-sm-0">
                                                                        <!-- <router-link to="/ecommerce/checkout"
                                                                    class="btn btn-success">
                                                                    <i class="mdi mdi-cart-arrow-right mr-1"></i> Checkout
                                                                </router-link> -->
                                                                    </div>
                                                                </div>
                                                                <!-- end col -->
                                                            </div>
                                                            <!-- end row-->
                                                        </div>
                                                    </div>
                                                </div>
                                                <div class="col-xl-3" v-show="showPrices">
                                                    <div class="card">
                                                        <div class="card-body">
                                                            <h4 class="card-title mb-3">Order Summary</h4>
                                                            <div class="table-responsive">
                                                                <table class="table mb-0">
                                                                    <tbody>
                                                                        <tr v-show="vatOn">
                                                                            <td>Total Excl VAT:</td>
                                                                            <td>R{{ exclusiveTotal }}</td>
                                                                        </tr>
                                                                        <tr v-show="shippingApplies">
                                                                            <td>Items Total</td>
                                                                            <td>R {{ orderTotal }}</td>
                                                                        </tr>
                                                                        <tr v-show="shippingApplies">
                                                                            <td>Delivery</td>
                                                                            <td>R 250</td>
                                                                        </tr>
                                                                        <tr v-show="!shippingApplies">
                                                                            <td>Delivery</td>
                                                                            <td>FREE</td>
                                                                        </tr>
                                                                        <tr v-show="vatOn">
                                                                            <td>Total VAT:</td>
                                                                            <td>R{{ vatTotal }}</td>
                                                                        </tr>
                                                                        <tr>
                                                                            <th>Total:</th>
                                                                            <th>R{{ finalTotal }}</th>
                                                                        </tr>
                                                                    </tbody>
                                                                </table>
                                                            </div>
                                                            <!-- end table-responsive -->
                                                        </div>
                                                    </div>
                                                    <!-- end card -->
                                                </div>
                                            </div>
                                            <div class="d-flex justify-content-around">
                                                <b-pagination
                                                    class=""
                                                    v-show="orderData.products.length > 5"
                                                    v-model="currentPage"
                                                    :per-page="perPage"
                                                    :total-rows="rows"
                                                    size="xl"
                                                    @input="onPaginationChange"
                                                    first-text="First"
                                                    prev-text="Prev"
                                                    next-text="Next"
                                                    last-text="Last"
                                                >
                                                </b-pagination>
                                            </div>
                                            <!-- end row -->
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </b-card-text>
                </b-tab>
                <b-tab tabindex="3" :disabled="shippingInfo">
                    <template v-slot:title>
                        <i class="bx bxs-truck d-block check-nav-icon mt-4 mb-2"></i>
                        <p class="font-weight-bold mb-4">Shipping Info</p>
                    </template>
                    <b-card-text>
                        <div class="card shadow-none border mb-0">
                            <div class="card-body">
                                <div class="card shadow-none border mb-0 pl-3 pr-4">
                                    <div class="row mt-2 mr-1">
                                        <div class="col-sm-6">
                                            <router-link tag="a" to="/ecommerce/cart" class="btn text-muted d-none d-sm-inline-block btn-link">
                                                <!-- <i class="mdi mdi-arrow-left mr-1"></i> Back to Shopping Cart -->
                                            </router-link>
                                        </div>
                                        <!-- end col -->
                                        <div class="col-sm-6">
                                            <div class="text-sm-right">
                                                <div class="text-sm-right">
                                                    <button @click="validateShipping()" class="btn btn-success">
                                                        <i class="mdi mdi-truck-fast mr-1"></i>
                                                        Proceed to Payment
                                                    </button>
                                                </div>
                                            </div>
                                        </div>
                                        <!-- end col -->
                                    </div>

                                    <h4 class="card-title mt-3">Shipping information</h4>
                                    <p class="card-title-desc">Fill all information below</p>
                                    <b-form ref="shippingForm" @submit="submitValidate()" class="mr-3">
                                        <b-row class="mb-4">
                                            <b-col md="2">
                                                <label for="input-name">Delivery Contact Person</label>
                                            </b-col>
                                            <b-col md="10">
                                                <b-form-input
                                                    id="input-name"
                                                    placeholder="Enter name"
                                                    v-model="$v.orderData.contact_person.$model"
                                                    :state="validateStateShipping('contact_person')"
                                                ></b-form-input>
                                            </b-col>
                                        </b-row>

                                        <b-row class="mb-4">
                                            <b-col md="2">
                                                <label for="billing-email-address">Email Address</label>
                                            </b-col>
                                            <b-col md="10">
                                                <b-form-input
                                                    id="billing-email-address"
                                                    placeholder="Enter your email"
                                                    v-model="$v.orderData.contact_email.$model"
                                                    :state="validateStateShipping('contact_email')"
                                                ></b-form-input>
                                            </b-col>
                                        </b-row>

                                        <b-row class="mb-4">
                                            <b-col md="2">
                                                <label for="billing-phone">Contact No.</label>
                                            </b-col>
                                            <b-col md="10">
                                                <b-form-input
                                                    id="billing-phone"
                                                    placeholder="Enter your Phone no."
                                                    v-model="$v.orderData.contact_no.$model"
                                                    :state="validateStateShipping('contact_no')"
                                                ></b-form-input>
                                            </b-col>
                                        </b-row>

                                        <b-row class="mb-4">
                                            <b-col md="2">
                                                <label for="billing-address">Address</label>
                                            </b-col>
                                            <b-col md="10">
                                                <b-form-textarea
                                                    id="billing-address"
                                                    rows="3"
                                                    placeholder="Enter full address"
                                                    v-model="$v.orderData.delivery_address.$model"
                                                    :state="validateStateShipping('delivery_address')"
                                                ></b-form-textarea>
                                            </b-col>
                                        </b-row>

                                        <b-row class="mb-4">
                                            <b-col md="2">
                                                <label for="city">City / Town</label>
                                            </b-col>
                                            <b-col md="10">
                                                <b-form-input
                                                    id="city"
                                                    placeholder="Enter delivery city / town"
                                                    v-model="$v.orderData.city.$model"
                                                    :state="validateStateShipping('city')"
                                                ></b-form-input>
                                            </b-col>
                                        </b-row>

                                        <b-row class="mb-4">
                                            <b-col md="2">
                                                <label for="city">Postal Code</label>
                                            </b-col>
                                            <b-col md="10">
                                                <b-form-input
                                                    id="city"
                                                    placeholder="Enter postal code"
                                                    v-model="$v.orderData.postal_code.$model"
                                                    :state="validateStateShipping('postal_code')"
                                                ></b-form-input>
                                            </b-col>
                                        </b-row>

                                        <div class="form-group row mb-4">
                                            <label class="col-md-2 col-form-label">Province</label>
                                            <div class="col-md-10">
                                                <b-form-select
                                                    :options="provinceOptions"
                                                    v-model="$v.orderData.province.$model"
                                                    :state="validateStateShipping('province')"
                                                ></b-form-select>
                                            </div>
                                        </div>
                                        <b-row class="mb-4">
                                            <b-col md="2">
                                                <label for="example-textarea">Order Notes:</label>
                                            </b-col>
                                            <b-col md="10">
                                                <b-form-textarea
                                                    id="example-textarea"
                                                    rows="3"
                                                    placeholder="Write some note.."
                                                    v-model="orderData.order_notes"
                                                ></b-form-textarea>
                                            </b-col>
                                        </b-row>
                                    </b-form>
                                </div>
                            </div>
                        </div>
                    </b-card-text>
                </b-tab>
                <b-tab tabindex="4" :disabled="paymentInfo">
                    <template v-slot:title>
                        <i class="bx bxs-credit-card  d-block check-nav-icon mt-4 mb-2"></i>
                        <p class="font-weight-bold mb-4">Payment</p>
                    </template>
                    <b-card-text>
                        <div class="card shadow-none border mb-0">
                            <div class="card-body">
                                <div class="card shadow-none border mb-0 pl-3 pr-4">

                                    <div class="col-sm-12">
                                        <h4 class="card-title mt-3">Payment information</h4>
                                        <p class="card-title-desc">Select a payment method below:</p>
                                    </div>
                                    
                                    <div class="col-sm-2">
                                        <button class="btn btn-rounded svg-button border-primary" @click="submitOrder()">
                                            <img src="@/assets/images/yoco.svg" alt="Yoco Logo" height="35" />
                                        </button>
                                    </div>

                                </div>
                            </div>
                        </div>
                    </b-card-text>
                </b-tab>
            </b-tabs>
        </div>
    </div>
</template>

<script>
import {
    clientComputed,
    clientMethods,
    productMethods,
    productComputed,
    orderMethods,
    orderComputed,
    adminComputed,
    repComputed,
} from "@/state/helpers";

import { validationMixin } from "vuelidate";
//eslint-disable-next-line
import { required, email, minLength } from "vuelidate/lib/validators";
//eslint-disable-next-line
import Swal from "sweetalert2";
import _ from "lodash";

export default {
    mixins: [validationMixin],
    page: {
        title: "Checkout",
    },
    data() {
        return {
            gridViewDM: true,
            gridView: true,
            productSelectionGroups: [],
            displayMaterialGroups: [],
            displayMaterialEnabled: false,
            clickedFilters: [],
            clickedFiltersDisplayMaterial: [],
            searchValue: "",
            searchValueDisplayMaterial: "",
            productsAtComponentMount: [],
            products: [],
            productsDisplayMaterialAtComponentMount: [],
            productsDisplayMaterialOnly: [],
            productsWithNoDisplayMaterial: [],
            // productsDisplayMaterialWithGroups: [],
            // client_data: "",
            filterModeFlag: false,
            formSubmit: false,
            title: "Create Order",
            tabIndex: 0,
            addProduct: "",
            // validateOn: false,
            showPrices: true,
            busySaving: false,
            orderData: {
                client_id: -1,
                branch_id: -1,
                rep_user_id: "",
                type: "",
                contact_person: "",
                contact_email: "",
                contact_no: "",
                delivery_address: "",
                province: "",
                city: "",
                postal_code: "",
                order_notes: "",
                properties: {},
                products: [],
            },
            productSelection: true,
            confirmation: true,
            shippingInfo: true,
            additionalInformation: true,
            paymentInfo: true,

            provinceOptions: [
                { value: "", text: "Please select an option" },
                { value: "Western Cape", text: "Western Cape" },
                { value: "Eastern Cape", text: "Eastern Cape" },
                { value: "Free State", text: "Free State" },
                { value: "Gauteng", text: "Gauteng" },
                { value: "KwaZulu-Natal", text: "KwaZulu-Natal" },
                { value: "Limpopo", text: "Limpopo" },
                { value: "Mpumalanga", text: "Mpumalanga" },
                { value: "North-West", text: "North-West" },
                { value: "Northern-Cape", text: "Northern-Cape" },
            ],
            //
            table_data: [],
            currentPage: 1,
            perPage: 5,
            currentPageConfirmationTab: 1,
            perPageConfirmationTab: 5,
            currentPageProductSelectionTab: 1,
            perPageProductSelectionTab: 10,
            currentPageDisplayMaterialTab: 1,
            perPageDisplayMaterialTab: 10,
        };
    },
    validations: {
        orderData: {
            contact_person: {
                required,
                minLength: minLength(3),
            },
            contact_email: {
                required,
            },
            contact_no: {
                required,
            },
            postal_code: {
                required,
            },
            province: {
                required,
            },
            delivery_address: {
                required,
            },
            city: {
                required,
                minLength: minLength(3),
            },
        },
    },
    props: {
        client_data: {
            required: true,
        },
        repUser: Boolean,
    },
    computed: {
        ...clientComputed,
        ...productComputed,
        ...adminComputed,
        ...orderComputed,
        ...repComputed,
        renderCategoriesProductSelection() {
            return  this.all_product_groups;
        },
        renderCategoriesDisplayMaterial() {
            //eslint-disable-next-line
            // console.log(this.all_product_groups);
            let productGroupsFilterDisplayTrue = this.all_product_groups.filter((group) => {
                return group.display_material == true;
            });

            return productGroupsFilterDisplayTrue;
        },
        paginatedItems() {
            return this.orderData.products.slice(this.currentPage * this.perPage - this.perPage, this.currentPage * this.perPage);
        },
        shippingApplies() {
            if (this.orderTotal >= 1000) {
                return false;
            }
            return true;
        },
        paginatedItemsConfirmationTab() {
            return this.orderData.products.slice(
                this.currentPageConfirmationTab * this.perPage - this.perPage,
                this.currentPageConfirmationTab * this.perPage
            );
        },
        paginatedItemsProductSelectionTab() {
            // if (flag == "table") {
            //     return this.products;
            // }

            return this.products.slice(
                this.currentPageProductSelectionTab * this.perPageProductSelectionTab - this.perPageProductSelectionTab,
                this.currentPageProductSelectionTab * this.perPageProductSelectionTab
            );
        },
        paginatedItemsDisplayMaterialTab() {
            return this.productsDisplayMaterialOnly.slice(
                this.currentPageDisplayMaterialTab * this.perPageDisplayMaterialTab - this.perPageDisplayMaterialTab,
                this.currentPageDisplayMaterialTab * this.perPageDisplayMaterialTab
            );
        },

        rows() {
            return this.orderData.products.length;
        },
        productSelectionRows() {
            return this.products.length;
        },
        displayMaterialRows() {
            return this.productsDisplayMaterialOnly.length;
        },
        vatOn() {
            return !!parseInt(this.core.site_config.vat_enabled);
        },
        searchProducts() {
            //eslint-disable-next-line
            console.log("now here");
            return _.map(this.all_products, "name");
        },
        orderTotal() {
            var el = this;
            var total = 0;
            var price, qty;
            _.forEach(el.orderData.products, function (value) {
                price = value.price;
                qty = value.qty;
                if (!el.vatOn) {
                    price = price * qty;
                    total += parseFloat(price.toFixed(2));
                } else {
                    price = price * qty;
                    price = price * 1.15;
                    total += parseFloat(price.toFixed(2));
                }
            });
            return total;
        },
        finalTotal() {
            if (this.orderTotal >= 1000) {
                return this.orderTotal;
            }
            return this.orderTotal + 250;
        },
        exclusiveTotal() {
            if (this.orderTotal > 0) {
                var total = this.orderTotal;
                total = total / 1.15;
                return parseFloat(total.toFixed(2));
            } else {
                return 0;
            }
        },
        vatTotal() {
            if (this.orderTotal > 0) {
                var total = this.orderTotal - this.orderTotal;
                return parseFloat(total.toFixed(2));
            }
            return 0;
        },
        returnCartQuanity(productid) {
            const productIndex = this.orderData.products.findIndex((product) => product.id === productid);

            return this.orderData.products[productIndex];
        },
    },
    methods: {
        ...clientMethods,
        ...productMethods,
        ...orderMethods,
        toggleViewDM() {
            this.gridViewDM = !this.gridViewDM;
        },
        toggleView() {
            this.gridView = !this.gridView;
        },
        getGroupNames(groups) {
            return groups.map(group => group.name).join(', ');
        },
        handleProductGroups(id) {
            //eslint-disable-next-line
            // console.log("all products", this.all_products);

            let groupBuilder = "";

            let findProduct = this.all_products.find((product) => product.id == id);
            //eslint-disable-next-line
            // console.log("findProduct", findProduct);

            if (typeof findProduct !== "undefined") {
                // Check if 'findProduct.groups' exists before proceeding
                if (findProduct.groups && findProduct.groups.length > 0) {
                    groupBuilder = findProduct.groups[0].name;
                    for (let i = 1; i < findProduct.groups.length; i++) {
                        groupBuilder += `, ${findProduct.groups[i].name}`;
                    }
                }
            }
            //eslint-disable-next-line
            // console.log("builder", groupBuilder);
            return groupBuilder;
        },
        handleDisplayMaterialGroups(id) {
            //eslint-disable-next-line
            // console.log("all products", this.all_display_material_products);

            let groupBuilder = "";

            let findProduct = this.all_display_material_products.find((product) => product.id == id);
            //eslint-disable-next-line
            // console.log("findProduct", findProduct);

            if (typeof findProduct !== "undefined") {
                // Check if 'findProduct.groups' exists before proceeding
                if (findProduct.groups && findProduct.groups.length > 0) {
                    groupBuilder = findProduct.groups[0].name;
                    for (let i = 1; i < findProduct.groups.length; i++) {
                        groupBuilder += `, ${findProduct.groups[i].name}`;
                    }
                }
            }
            //eslint-disable-next-line
            // console.log("builderFor display material", groupBuilder);
            return groupBuilder;
        },

        handleDisplayMaterialTabClick() {
            this.clickedFilters.length = 0;

            // let test = this.renderCategoriesDisplayMaterial();
            //eslint-disable-next-line
            let productGroupsFilterDisplayTrue = this.all_product_groups.filter((group) => {
                return group.display_material == true;
            });

            // return productGroupsFilterDisplayTrue;

            // alert(productGroupsFilterDisplayTrue);
        },
        onChangeCartInput(productid, e) {
            //eslint-disable-next-line
            console.log(this.orderData, productid);

            const index = this.orderData.products.findIndex((product) => product.id === productid);
            //eslint-disable-next-line
            console.log("index", index);

            if (index !== -1) {
                //eslint-disable-next-line
                let quantity = Number(this.orderData.products[index].qty);
                this.orderData.products[index].qty = Number(e.target.value);
            }
        },
        //eslint-disable-next-line
        editCartQuanityIncrease(productid) {
            //eslint-disable-next-line
            console.log(this.orderData, productid);

            const index = this.orderData.products.findIndex((product) => product.id === productid);
            //eslint-disable-next-line
            console.log("index", index);

            if (index !== -1) {
                let quantity = Number(this.orderData.products[index].qty);
                this.orderData.products[index].qty = quantity + 1;
            }
        },

        editCartQuanityDecrease(productid) {
            const productIndex = this.orderData.products.findIndex((product) => product.id === productid);

            this.editProductQty(productid, this.orderData.products[productIndex].qty - 1);
        },
        totalItemsInCart() {
            let totalQty = 0;
            this.orderData.products.forEach((product) => {
                totalQty += Number(product.qty);
            });
            return totalQty;
        },
        lookIfInCart(productid) {
            //eslint-disable-next-line

            const findProduct = this.orderData.products.find((product) => product.id === productid);
            //eslint-disable-next-line
            // console.log("find product", findProduct, typeof findProduct !== "undefined");

            return typeof findProduct !== "undefined";
        },
        categoryFilter(e, productGroup) {
            if (e === "active") {
                //added it to the clickdFilters array
                this.clickedFilters.push(productGroup);

                this.products = []; // Clear the products array first

                this.buildProductsWithCategories();
            } else {
                // not_active
                const index = this.clickedFilters.indexOf(productGroup);

                if (index >= 0) {
                    this.clickedFilters.splice(index, 1); //remove

                    if (this.clickedFilters.length > 0) {
                        this.buildProductsWithCategories();
                    } else {
                        this.products = [...this.productsAtComponentMount];
                    }
                }
                this.searchFilter(this.searchValue, this.products);
            }
        },
        categoryFilterDisplayMaterial(e, productGroup) {
            if (e === "active") {
                //added it to the clickdFilters array
                //eslint-disable-next-line
                console.log("what is the product group", productGroup);
                this.clickedFiltersDisplayMaterial.push(productGroup);

                this.productsDisplayMaterialOnly = []; // Clear the products array first

                this.buildProductsWithCategoriesDisplayMaterial();
            } else {
                // not_active
                const index = this.clickedFiltersDisplayMaterial.indexOf(productGroup);

                if (index >= 0) {
                    this.clickedFiltersDisplayMaterial.splice(index, 1); //remove
                    //eslint-disable-next-line
                    console.log("the fitlers", this.clickedFiltersDisplayMaterial);
                    if (this.clickedFiltersDisplayMaterial.length > 0) {
                        this.buildProductsWithCategoriesDisplayMaterial();
                    } else {
                        this.productsDisplayMaterialOnly = [...this.productsDisplayMaterialAtComponentMount];
                    }
                }
                // this.searchFilterDisplayMaterial(this.searchValueDisplayMaterial, this.productsDisplayMaterialAtComponentMount);
            }
        },
        // this methods just takes the checkedFilters array and finds its elements in the all_products group array and creates a product array
        buildProductsWithCategories() {
            this.products = []; // Clear the products array first

            for (let i = 0; i < this.clickedFilters.length; i++) {
                for (let j = 0; j < this.all_product_groups.length; j++) {
                    if (this.clickedFilters[i] === this.all_product_groups[j].name) {
                        this.products.push(...this.all_product_groups[j].products);
                    }
                }
            }

            //apply the text filter
            if (this.searchValue.length > 0) {
                //eslint-disable-next-line
                console.log("it is being executed");
                this.searchFilter(this.searchValue, this.products);
            }
            //eslint-disable-next-line
            console.log("clicked fitlesr", this.clickedFilters);
        },
        buildProductsWithCategoriesDisplayMaterial() {
            this.productsDisplayMaterialOnly = []; // Clear the products array first
            // const uniqueProductIds = new Set();
            //eslint-disable-next-line
            // console.log("dd", this.clickedFiltersDisplayMaterial, this.displayMaterialGroups);
            for (let i = 0; i < this.clickedFiltersDisplayMaterial.length; i++) {
                for (let j = 0; j < this.displayMaterialGroups.length; j++) {
                    if (this.clickedFiltersDisplayMaterial[i] == this.displayMaterialGroups[j].name) {
                        //eslint-disable-next-line
                        console.log("whats going on here", this.displayMaterialGroups[j].products);
                        this.productsDisplayMaterialOnly.push(...this.displayMaterialGroups[j].products);

                        //eslint-disable-next-line
                        console.log("whats going on here2", this.productsDisplayMaterialOnly);
                        break;
                    }
                }
            }
            // method below if you dont want the break
            // for (const filter of this.clickedFiltersDisplayMaterial) {
            //     for (const group of this.displayMaterialGroups) {
            //         if (filter === group.name) {
            //             for (const product of group.products) {
            //                 if (!uniqueProductIds.has(product.id)) {
            //                     // Check if the product is unique
            //                     this.productsDisplayMaterialOnly.push(product);
            //                     uniqueProductIds.add(product.id); // Add the product's ID to the set
            //                 }
            //             }
            //         }
            //     }
            // }

            //apply the text filter
            if (this.searchValueDisplayMaterial.length > 0) {
                //eslint-disable-next-line
                console.log("it is being executed");
                this.searchFilterDisplayMaterial(this.searchValueDisplayMaterial, this.productsDisplayMaterialOnly);
            }
            //eslint-disable-next-line
            console.log("clicked fitlesr", this.clickedFiltersDisplayMaterial);
        },
        searchFilter(e, arrayToSearch) {
            this.searchValue = e;

            this.products = arrayToSearch.filter((product) => {
                let p = product.name.toLowerCase().search(this.searchValue.toLowerCase()) !== -1;
                //eslint-disable-next-line
                // console.log(p);
                return p;
            });
        },
        searchFilterDisplayMaterial(e, arrayToSearch) {
            this.searchValueDisplayMaterial = e;

            this.productsDisplayMaterialOnly = arrayToSearch.filter((product) => {
                let p = product.name.toLowerCase().search(this.searchValueDisplayMaterial.toLowerCase()) !== -1;
                //eslint-disable-next-line
                // console.log(p);
                return p;
            });
        },
        productSelectionClick(productId) {
            var findIndex = _.findIndex(this.productsAtComponentMount, function (o) {
                return o.id == productId;
            });
            if (findIndex > -1) {
                var productData = {
                    id: this.productsAtComponentMount[findIndex].id,
                    name: this.productsAtComponentMount[findIndex].name,
                    sku: this.productsAtComponentMount[findIndex].sku,
                    group: this.productsAtComponentMount[findIndex].group,
                    qty: 1,
                    price: parseFloat(this.productsAtComponentMount[findIndex].price),
                    public_img_src: this.productsAtComponentMount[findIndex].public_img_src,
                };
                this.orderData.products.push(productData);
                this.addProduct = "";
            }
            //eslint-disable-next-line
            console.log(productId);
        },
        onPaginationChange(newPage) {
            this.currentPage = newPage;
        },
        onPaginationChangeConfirmationTab(newPage) {
            this.currentPageConfirmationTab = newPage;
        },
        onPaginationChangeProductSelectionTab(newPage) {
            this.currentPageProductSelectionTab = newPage;
        },
        validateStateShipping(name) {
            if (this.formSubmit) {
                const { $dirty, $error } = this.$v.orderData[name];
                return $dirty ? !$error : null;
            }
        },
        addOrderProduct() {
            //eslint-disable-next-line
            console.log("over ehre");
            var el = this;
            var findIndex = _.findIndex(this.all_products, function (o) {
                return o.name == el.addProduct;
            });
            if (findIndex > -1) {
                var productData = {
                    id: this.all_products[findIndex].id,
                    name: this.all_products[findIndex].name,
                    sku: this.all_products[findIndex].sku,
                    group: this.all_products[findIndex].group,
                    qty: 1,
                    price: parseFloat(this.all_products[findIndex].price),
                    //retrieves the actual img
                    public_img_src: this.all_products[findIndex].public_img_src,
                };
                this.orderData.products.push(productData);
                this.addProduct = "";
            }
        },
        renderPrice(price, qty) {
            if (!this.vatOn) {
                price = price * qty;
                return parseFloat(price.toFixed(2));
            }
            var incl = price * 1.15;
            incl = incl * qty;
            return parseFloat(incl.toFixed(2));
        },
        renderTotal(key) {
            var total = 0;
            var price, qty;
            while (key >= 0) {
                price = this.orderData.products[key].price;
                qty = this.orderData.products[key].qty;
                if (!this.vatOn) {
                    price = price * qty;
                    total += parseFloat(price.toFixed(2));
                } else {
                    price = price * qty;
                    price = price * 1.15;
                    total += parseFloat(price.toFixed(2));
                }
                key--;
            }
            return parseFloat(total.toFixed(2));
        },
        removeOrderProduct(id) {
            // Find the product with the given id in the products array
            const productIndex = this.orderData.products.findIndex((product) => product.id === id);

            if (productIndex !== -1) {
                // If the product with the given id is found, remove it from the array
                this.orderData.products.splice(productIndex, 1);
            } else {
                // If the product with the given id is not found, you might want to handle this case accordingly.
                //eslint-disable-next-line
                // console.error(`Product with id "${id}" not found.`);
            }

            //eslint-disable-next-line
            // console.log(this.orderData.products);
        },
        editProductQty(id, qty) {
            const productIndex = this.orderData.products.findIndex((product) => product.id === id);

            if (productIndex === -1) {
                return;
            }

            if (qty === 0) {
                // If quantity is zero, remove the product from the array
                this.orderData.products.splice(productIndex, 1);
            } else {
                // Update the quantity of the product with the given id
                if (this.orderData.products[productIndex].qty > 0) {
                    this.orderData.products[productIndex].qty = qty;
                }
            }
        },
        validateProductSelection() {
            // this.switchTab
            this.$nextTick(() => {
                this.tabIndex++;
            });
        },
        validateCart() {
            if (this.orderData.products.length > 0) {
                this.shippingInfo = false;

                this.$nextTick(() => {
                    this.tabIndex++;
                });
            }
        },
        validateShipping() {
            this.formSubmit = true;

            this.$v.orderData.$touch();

            if (this.$v.orderData.$anyError) {
                return;
            }

            this.paymentInfo = false;

            this.$nextTick(() => {
                this.tabIndex++;
            });
        },

        validateAdditionalInformation() {
            // this.shippingInfo = false;

            this.$nextTick(() => {
                this.tabIndex++;
            });
        },

        submitOrder() {
            this.$emit("busySaving", true);

            if (this.repUser) {
                this.orderData.rep_user_id = this.selected_rep_id;
            }

            this.createOrder(this.orderData).then((response) => {
                if (response.success) {
                    /*this.$router
                        .push({
                            path: `/orders/view/order/${response.order_id}`,
                        })
                        .then(Swal.fire("Success!", "Your order was submitted!", "success"));*/
                        window.location.href = response.payment_url;
                }
            });
        },
    },
    mounted() {
        if (this.all_products.length == 0) {
            this.getProducts().then(() => {
                // this.all_products_at_component_mount = [...this.all_products];
                // this.products = this.all_products;
                // this.productsDisplayMaterialOnly = [...this.all_products];

                //
                //eslint-disable-next-line
                console.log("all products", this.all_products);

                // for (const product of this.productsDisplayMaterialOnly) {
                //     if (product.groups.some((group) => group.display_material == true)) {
                //         this.productsDisplayMaterialOnly.push(product);
                //     }
                // }
                //eslint-disable-next-line
                // console.log("test", test);
            });
        } else {
            this.products = this.all_products;
        }

        this.setAllDisplayMaterialProducts().then(() => {
            // this.productsDisplayMaterialWithGroups = this.all_display_material_products;
            // //eslint-disable-next-line
            // console.log("with groups", this.productsDisplayMaterialWithGroups);
        });

        this.orderData = {
            client_id: this.client_data.id,
            branch_id: this.selected_branch_id,
            rep_user_id: "",
            type: "client_order",
            contact_person: this.client_data.contact_person,
            contact_email: this.client_data.emails,
            contact_no: this.client_data.contact_no,
            delivery_address: this.client_data.address,
            province: this.client_data.province,
            city: this.client_data.city,
            postal_code: this.client_data.postal_code,
            order_notes: "",
            properties: {},
            products: [],
        };

        if (this.order_properties.length == 0) {
            this.getOrderProperties();
        }

        this.setAllProductGroups().then(() => {
            let localProducts = [];
            let productsDisplayMaterials = [];

            for (let i = 0; i < this.all_product_groups.length; i++) {
                if (this.all_product_groups[i].products.length > 0) {
                    for (let j = 0; j < this.all_product_groups[i].products.length; j++) {
                        localProducts.push(this.all_product_groups[i].products[j]);

                        //sees if the groups display_nmaterial is true if it is push it the corect array
                        if (this.all_product_groups[i].display_material == true) {
                            productsDisplayMaterials.push(this.all_product_groups[i].products[j]);
                            this.displayMaterialGroups.push(this.all_product_groups[i]);
                        } else {
                            this.productSelectionGroups.push(this.all_product_groups[i]);
                            this.productsWithNoDisplayMaterial.push(this.all_product_groups[i].products[j]);
                        }
                    }
                }
            }
            this.productsAtComponentMount = [...localProducts];
            this.products = [...localProducts]; // i.e. all products
            //set the array for the display material tab
            this.productsDisplayMaterialAtComponentMount = [...productsDisplayMaterials];
            this.productsDisplayMaterialOnly = [...productsDisplayMaterials];
        });

        /*if (this.core.role_name != "admin") {
            this.displayMaterialEnabled = true;
        }*/
    },
};
</script>

<style lang="scss">
#target_ul_class__BV_tab_controls_ {
    display: flex;
    justify-content: center;

    > li {
        width: 200px;
        margin-left: 20px;
    }
}

.product-description {
    max-width: 50ch; /* Limits to approximately 50 characters width */
    white-space: normal; /* Ensures text wraps */
    word-wrap: break-word; /* Forces long words to break */
    overflow-wrap: break-word; /* Ensures compatibility across browsers */
    text-overflow: clip; /* Prevents text truncation with ellipsis */
    font-size: 0.8rem; /* Consistent font sizing */
    line-height: 1.5; /* Improves readability */
    color: #333; /* Optional: Adjust text color */
    margin-bottom: 0.5rem; /* Space below the description */
}

.td-text-wrap {
    max-width: 50ch; /* Limits width to 50 characters approximately */
    white-space: normal !important; /* Ensures text wraps */
    word-wrap: break-word !important; /* Ensures long words break correctly */
    overflow-wrap: break-word !important; /* Better compatibility for wrapping */
    text-overflow: clip; /* Ensures no ellipsis, just wraps */
}

.img-class {
    width: 250px;
}

.product-name-max-length {
    max-width: 240px;
    height: 60px;
}
.pagination-container {
    position: sticky;
    bottom: 0;
    left: 0;
    width: 100%;
    background-color: white; /* You can adjust the background color to match your design */
    z-index: 999; /* Ensure the pagination bar stays on top of other elements */
}
.qty-width {
    width: 30px;
}

.icon-resize {
    height: 50px;
    width: 50px;
    size: 100px;
}
.svg-button {
  /* Remove default button styles */
  background: transparent;
  padding: 10px;
  border-width: 3px;

  /* Add some transitions for hover effects */
  transition: all 0.3s ease-in-out;
}

.svg-button:hover {
  /* Cool border effect on hover */
  border-color: #ff6347;
  box-shadow: 0 0 10px rgba(255, 99, 71, 0.6);
}

.svg-button img {
  /* Smooth scaling of the SVG */
  transition: transform 0.3s ease-in-out;
}

</style>
